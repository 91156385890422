import { useState, useEffect } from "react";
import { Form, Input, Space, Button, Typography, Modal, message } from "antd";
import { useHistory, useParams } from "react-router-dom";

import FormOptionList from "components/form/FormOptionList";
import OperatingSystemSelector from "components/form/OperatingSystemSelector";
import { defaultErrorHandler, validationSuccess } from "utils/errorHandling";
import api from "api";
import { useQueryClient } from "react-query";
import { useFormStyles } from "hooks/styles";
import useFormStore from "hooks/useFormStore";

const { confirm } = Modal;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 },
    },
};

function ComputerForm() {
    const computerName = useFormStore((state) => state.computerName);
    const setComputerName = useFormStore((state) => state.setComputerName);
    const computerNameStatus = useFormStore((state) => state.computerNameValidation);
    const invalidOptions = useFormStore((state) => state.invalidOptions);
    const setInvalidOptions = useFormStore((state) => state.setInvalidOptions);
    const installComputerLoading = useFormStore((state) => state.installComputerLoading);
    const templateSaveName = useFormStore((state) => state.templateSaveName);
    const setTemplateSaveName = useFormStore((state) => state.setTemplateSaveName);
    const optionValues = useFormStore((state) => state.optionValues);
    const setInstallComputerLoading = useFormStore((state) => state.setInstallComputerLoading);
    const operatingSystem = useFormStore((state) => state.operatingSystem);
    const setOperatingSystem = useFormStore((state) => state.setOperatingSystem);
    const getOperatingSystemOptions = useFormStore((state) => state.getOperatingSystemOptions);

    const [saveTemplateModalVisible, setSaveTemplateModalVisible] = useState(false);

    const classes = useFormStyles();
    const history = useHistory();
    const { template, computer } = useParams();
    const queryClient = useQueryClient();

    useEffect(async () => {
        if (template) {
            try {
                const templateResponse = await api.getTemplate(template);
                const osName = templateResponse.data?.os;
                setOperatingSystem(osName);
                const { options } = await api.getTemplateOptions(template);
                getOperatingSystemOptions(osName, options, true);
            } catch (error) {
                defaultErrorHandler(error);
            }
        }
    }, [template]);

    useEffect(async () => {
        if (computer) {
            try {
                await api.testComputerName(computer);
                setComputerName(computer);
            } catch (error) {
                defaultErrorHandler(error);
            }
        }
    }, [computer]);

    // Gets called when user clicks "Install Computer" button
    // Asks for permission to install computer and then calls method to install computer
    const handleComputerInstall = () => {
        // Prevent install action when there is no hostname entered
        if (computerName === "") {
            message.warning("Computer could not be installed because computer name is empty");
            return;
        }

        if (operatingSystem === "none") {
            message.warning("Computer could not be installed because no operating system is selected");
            return;
        }

        // Prevent install action when there are errors in the form or with the computer name
        if (!validationSuccess(computerNameStatus) || invalidOptions.length !== 0) {
            message.warning("Computer could not be installed because of errors in the form");
            return;
        }

        confirm({
            title: `Do you want to install ${computerName}?`,
            content: `Do you really want to install ${computerName}? All local data may be lost, depending on the partitioning you selected!`,
            okText: "Install",
            cancelText: "No",
            async onOk() {
                await installComputer();
            },
        });
    };

    const installComputer = async () => {
        setInstallComputerLoading(true);

        try {
            const response = await api.saveTemplate({ ...optionValues, os: operatingSystem });
            await api.saveComputer({ ...response.data, name: computerName });

            queryClient.invalidateQueries("computers");
            queryClient.invalidateQueries("templates");
            history.push(`/computers/${computerName}`);
        } catch (error) {
            const { data } = error.response;

            if (Object.prototype.hasOwnProperty.call(data, "invalid")) {
                setInvalidOptions(data.invalid);
            } else {
                defaultErrorHandler(error);
            }
        } finally {
            setInstallComputerLoading(false);
        }
    };

    // Gets called when user clicks "Save Template" button
    // Asks for permission to save template and then calls method to save template
    const handleSaveTemplate = () => {
        if (operatingSystem === "none") {
            message.warning("Template could not be saved because no operating system is selected");
            return;
        }

        // Prevent install action when there are errors in the form or with the computer name
        if (!validationSuccess(computerNameStatus) || invalidOptions.length !== 0) {
            message.warning("Template could not be saved because of errors in the form");
            return;
        }

        setSaveTemplateModalVisible(true);
    };

    const saveTemplate = async () => {
        // Ensure template name length is between 2 and 250 characters
        if (templateSaveName.length < 1 || templateSaveName.length >= 250) {
            message.error("This template name is invalid. It must not be empty or longer than 250 characters");
            return;
        }

        try {
            const response = await api.saveTemplate({
                ...optionValues,
                name: templateSaveName,
                os: operatingSystem,
            });

            history.push(`/templates/${response.data.template}`);
            queryClient.invalidateQueries("templates");
        } catch (error) {
            defaultErrorHandler(error);
        }
    };

    return (
        <div className={classes.component}>
            <Typography.Title level={3}>Computer</Typography.Title>
            <Form {...formItemLayout}>
                <OperatingSystemSelector />
                <FormOptionList />
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button type="primary" onClick={handleComputerInstall} loading={installComputerLoading}>
                            Install Computer
                        </Button>
                        <Button onClick={handleSaveTemplate}>Save Template</Button>
                    </Space>
                </Form.Item>
            </Form>
            <Modal
                title="Save template"
                okText="Save"
                visible={saveTemplateModalVisible}
                onOk={async () => {
                    await saveTemplate();
                    setSaveTemplateModalVisible(false);
                    setTemplateSaveName("");
                }}
                onCancel={() => {
                    setSaveTemplateModalVisible(false);
                    setTemplateSaveName("");
                }}
            >
                <Input
                    confirm="Template name"
                    value={templateSaveName}
                    onChange={(event) => {
                        setTemplateSaveName(event.target.value);
                    }}
                />
            </Modal>
        </div>
    );
}

export default ComputerForm;
