import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Typography, Form, Input, Checkbox, DatePicker, Button, message } from "antd";
import { endOfDay, sub, formatISO } from "date-fns";

import api from "api";
import { useDebounce } from "hooks/debounce";
import { defaultErrorHandler, extractMessage, validationSuccess } from "utils/errorHandling";
import { useFormStyles } from "hooks/styles";
import useFormStore from "hooks/useFormStore";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 },
    },
};

function DelegationForm() {
    const [invalid, setInvalid] = useState(null);
    const [user, setUser] = useState("");
    const [root, setRoot] = useState(false);
    const [usernameValidation, setUsernameValidation] = useState({});

    const computerName = useFormStore((state) => state.computerName);
    const computerNameValidation = useFormStore((state) => state.computerNameValidation);

    const debouncedUser = useDebounce(user, 300);
    const classes = useFormStyles();
    const history = useHistory();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (debouncedUser !== "") {
            testUsername(debouncedUser);
        } else {
            setUsernameValidation({});
        }
    }, [debouncedUser]);

    const { mutate: dispatchSaveDelegation, isLoading: saveDelegationLoading } = useMutation(
        (request) => api.saveDelegation(request),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries("delegations");
                message.success(`Created delegation for ${variables.computer}`);
                history.push(`/computers/${variables.computer}`);
            },
            onError: defaultErrorHandler,
        },
    );

    const testUsername = async (username) => {
        setUsernameValidation({ validateStatus: "validating" });

        try {
            const response = await api.testUser(username);
            if (response.data) {
                setUsernameValidation({
                    validateStatus: "error",
                    help: response.data.message,
                });
                return;
            }

            setUsernameValidation({
                validateStatus: "success",
                help: "This is a valid username",
            });
        } catch (error) {
            setUsernameValidation({
                validateStatus: "error",
                help: extractMessage(error),
            });
        }
    };

    const createDelegation = () => {
        if (computerName === "") {
            message.warning("Enter a computer name in order to perform the delegation");
            return;
        }

        if (user === "") {
            message.warning("Enter a user name in order to execute the delegation");
            return;
        }

        if (!validationSuccess(computerNameValidation) || !validationSuccess(usernameValidation)) {
            message.warning("Delegation could not be performed because of errors in the form");
            return;
        }

        dispatchSaveDelegation({
            computer: computerName,
            invalid: invalid ? formatISO(new Date(invalid)) : null,
            user,
            root,
        });
    };

    return (
        <div className={`${classes.component} ${classes.delegationComponent}`}>
            <Typography.Title level={3}>Delegation</Typography.Title>

            <Form {...formItemLayout}>
                <Form.Item label="User Name" {...usernameValidation} hasFeedback>
                    <Input value={user} onChange={(event) => setUser(event.target.value)} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Checkbox checked={root} onChange={(event) => setRoot(event.target.checked)}>
                        Allow user to become root/Administrator.
                    </Checkbox>
                </Form.Item>
                <Form.Item label="Expiry Date">
                    <DatePicker
                        showTime={{ format: "HH:mm", minuteStep: 5 }}
                        value={invalid}
                        placeholder="Select Time"
                        onChange={(date) => setInvalid(date)}
                        onOk={(date) => setInvalid(date)}
                        //  TODO: is this right?
                        disabledDate={(value) => value < endOfDay(sub(new Date(), { days: 1 }))}
                    />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="button" onClick={createDelegation} loading={saveDelegationLoading}>
                        Delegate
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default DelegationForm;
