import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";

const localStorageKey = "user-crypto";
const key = "Q^dn6wD!etuX";

// Get user object from local storage and return as JSON
const getAuthentication = () => {
    const user = JSON.parse(localStorage.getItem(localStorageKey));

    if (!user || !Object.prototype.hasOwnProperty.call(user, "password")) {
        return null;
    }

    const { password } = user;

    const cleartextPassword = AES.decrypt(password, key).toString(CryptoJS.enc.Utf8);

    user.password = cleartextPassword;

    return user;
};

// Save user object to local storage
const setAuthentication = (username, password, admin) => {
    const encryptedPassword = AES.encrypt(password, key).toString();

    const user = {
        username,
        password: encryptedPassword,
        admin,
    };

    localStorage.setItem(localStorageKey, JSON.stringify(user));
};

// Delete user object from local storage
const deleteAuthentication = () => {
    localStorage.removeItem(localStorageKey);
};

const userService = {
    getAuthentication,
    setAuthentication,
    deleteAuthentication,
};

export default userService;
