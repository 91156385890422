import PropTypes from "prop-types";
import { Divider, Tag } from "antd";

import ApiCollapse from "components/api/ApiCollapse";

// Styling
// import "components/api/JsonTheme.css";

const generateParameterString = (parameters) => {
    if (Array.isArray(parameters)) {
        return "";
    }

    let parameterString = "?";

    Object.keys(parameters).forEach((key, index) => {
        if (index > 0) {
            parameterString = parameterString.concat("&");
        }

        parameterString = parameterString.concat(`${key}=${parameters[key]}`);
    });

    return parameterString;
};

function ApiFunction({ action }) {
    const parameterString = generateParameterString(action.parameters);

    if (!action) return null;

    return (
        <>
            <p>
                Method: <Tag color="geekblue">{action.method}</Tag>
            </p>
            <p>
                Endpoint: <b>/{action.url}/</b>
                {parameterString}
            </p>
            {action.documentation && (
                <>
                    <p>{action.documentation.description}</p>
                    <ApiCollapse documentation={action.documentation} method={action.method} />
                </>
            )}
            <Divider />
        </>
    );
}

ApiFunction.propTypes = {
    action: PropTypes.object.isRequired,
};

export default ApiFunction;
