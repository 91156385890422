import { createUseStyles } from "react-jss";

const useFormStyles = createUseStyles({
    component: {
        padding: "0 24px",
        maxWidth: 700,
    },
    delegationComponent: { paddingBottom: 24 },
});

export { useFormStyles };
