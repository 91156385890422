import PageLayout from "components/pages/PageLayout";
import ApiList from "components/api/ApiList";

function ComputerPage() {
    return (
        <PageLayout page="api">
            <ApiList />
        </PageLayout>
    );
}

export default ComputerPage;
