import PropTypes from "prop-types";
import { format } from "date-fns";

// TODO: Deal with timezone????

// Add the expiration date to the username if the delegation has one
// Otherwise only render username element
function ComputerDelegationElement({ computer }) {
    // if there is no delegation available, render nothing
    if (!computer.hasDelegation) return null;
    if (!computer.user) return null;
    if (!computer.invalid) return null;

    // If computer has an expiration date, show it
    return <span style={{ marginLeft: 10 }}>until {format(new Date(computer.invalid), "dd MMM yyyy")}</span>;
}

ComputerDelegationElement.propTypes = {
    computer: PropTypes.object.isRequired,
};

export default ComputerDelegationElement;
