import api from "api";
import { useQuery } from "react-query";
import AuthenticationService from "utils/authentication";

const queryConfig = {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    retry: 1,
};

const loadApiDocumentation = async () => {
    const credentials = AuthenticationService.getAuthentication();
    const { data } = await api.getAPIDocumentation(!!credentials);
    return data;
};

const useApiDocumentation = () => {
    const { status, data, error } = useQuery("api", loadApiDocumentation, queryConfig);

    return { data: data || [], error, status };
};

export { useApiDocumentation };
