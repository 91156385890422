import PropTypes from "prop-types";
import { Tooltip } from "antd";

import { OS_ICONS } from "configuration";

function OperatingSystemLogo({ os, display }) {
    // Use unknown image if no image is found for name
    const imagePath = OS_ICONS[os] || OS_ICONS.unknown;

    return (
        <Tooltip title={display}>
            <img src={`${process.env.PUBLIC_URL}/${imagePath}`} style={{ width: "30px" }} alt={display} />
        </Tooltip>
    );
}

OperatingSystemLogo.propTypes = {
    os: PropTypes.string.isRequired,
    display: PropTypes.string,
};

OperatingSystemLogo.defaultProps = {
    display: "none",
};

export default OperatingSystemLogo;
