const filterComputers = (computers, searchTerm) => {
    if (searchTerm === "") return computers;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return computers.filter((computer) => {
        const computerNameCondition = computer.name.toLowerCase().includes(lowerCaseSearchTerm);

        if (computer.hasDelegation && !computer.hasComputer) {
            const usernameCondition = computer.user.toLowerCase().includes(lowerCaseSearchTerm);
            return computerNameCondition || usernameCondition;
        }

        const osNameCondition = computer.os.name.toLowerCase().includes(lowerCaseSearchTerm);
        const osDisplayCondition = computer.os.display.toLowerCase().includes(lowerCaseSearchTerm);
        const statusCondition = computer.status ? computer.status.toLowerCase().includes(lowerCaseSearchTerm) : false;
        const statusNoteCondition = computers.statusNote
            ? computer.statusNote.toLowerCase().includes(lowerCaseSearchTerm)
            : false;

        if (!computer.hasDelegation && computer.hasComputer) {
            return (
                computerNameCondition || osNameCondition || osDisplayCondition || statusCondition || statusNoteCondition
            );
        }

        const usernameCondition = computer.user.toLowerCase().includes(lowerCaseSearchTerm);

        return (
            computerNameCondition ||
            usernameCondition ||
            osNameCondition ||
            osDisplayCondition ||
            statusCondition ||
            statusNoteCondition
        );
    });
};

// Given a list of computers and a list of delegations
// Some entities are only found in the computers OR delegations list
// Every entity needs to be shown even if it is only found in one list

// We'll call this computer even tho it is a mix of delegations and computers
// Hostname of comptuer or delegation can be used as an unique identifier key
const parseComputers = (computers, delegations) => {
    if (!computers || !delegations) {
        return {};
    }

    const computerList = {};

    delegations.reverse();
    delegations.forEach((delegation) => {
        let breakLoop = false;

        // Case delegation only
        computers.forEach((computer) => {
            if (delegation.computer.name === computer.name) {
                breakLoop = true;
            }
        });

        if (!breakLoop) {
            computerList[delegation.computer.name] = {
                name: delegation.computer.name,
                user: delegation.user,
                root: delegation.root,
                invalid: delegation.invalid,
                delegationId: delegation.id,
                hasComputer: false,
                hasDelegation: true,
            };
        }
    });

    computers.forEach((computer) => {
        let breakLoop = false;

        // Case computer only
        delegations.forEach((delegation) => {
            if (delegation.computer.name === computer.name) {
                breakLoop = true;
            }
        });

        if (!breakLoop) {
            // Add the whole computer object to the list
            computerList[computer.name] = {
                os: computer.template.os,
                name: computer.name,
                installPercentage: computer.status.percent,
                valid: computer.valid,
                status: computer.status.message,
                statusNote: computer.status.note,
                statusValid: computer.status.valid,
                templateId: computer.template.id,
                hasComputer: true,
                hasDelegation: false,
            };
        }
    });

    // Case computer and delegation
    computers.forEach((computer) => {
        delegations.forEach((delegation) => {
            if (delegation.computer.name === computer.name) {
                computerList[computer.name] = {
                    os: computer.template.os,
                    name: computer.name,
                    installPercentage: computer.status.percent,
                    valid: computer.valid,
                    status: computer.status.message,
                    statusNote: computer.status.note,
                    statusValid: computer.status.valid,
                    delegationId: delegation.id,
                    templateId: computer.template.id,
                    hasComputer: true,
                    hasDelegation: true,
                    user: delegation.user,
                    root: delegation.root,
                    invalid: delegation.invalid,
                };
            }
        });
    });

    return computerList;
};

export { filterComputers, parseComputers };
