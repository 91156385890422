import PageLayout from "components/pages/PageLayout";
import TemplateList from "components/template/TemplateList";

function TemplatePage() {
    return (
        <PageLayout page="template">
            <TemplateList />
        </PageLayout>
    );
}

export default TemplatePage;
