import { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { differenceInSeconds } from "date-fns";

import OperatingSystemLogo from "components/util-components/OperatingSystemLogo";
import ProgressElement from "components/util-components/ProgressElement";
import ComputerDropdown from "components/computers/ComputerDropdown";
import ComputerDelegationElement from "components/computers/ComputerDelegationElement";
import ComputerUserElement from "components/computers/ComputerUserElement";
import ComputerName from "components/util-components/ComputerName";

const { Column } = Table;

const useStyles = createUseStyles({
    table: {
        background: "#fff",
        "& .table-highlight": { backgroundColor: "#95de64", "&:hover": { backgroundColor: "transparent" } },
    },
});

function ComputerTable({ computers, loading }) {
    const { computer } = useParams();
    const classes = useStyles();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: computers.length,
    });

    return (
        <>
            <Table
                dataSource={computers}
                pagination={pagination}
                loading={loading}
                onChange={(pagination) => setPagination(pagination)}
                rowClassName={(record) => {
                    if (record.key === computer) return "table-highlight";
                }}
                className={classes.table}
                scroll={{ x: "max-content" }}
            >
                <Column
                    title="OS"
                    key="os"
                    render={(record) => {
                        if (record.hasComputer)
                            return <OperatingSystemLogo os={record.os.name} display={record.os.display} />;
                        return <OperatingSystemLogo os="none" />;
                    }}
                    sorter={(a, b) => a.osDisplay.localeCompare(b.osDisplay)}
                />
                <Column
                    title="Computer"
                    dataIndex="name"
                    key="name"
                    render={(name) => <ComputerName name={name} />}
                    sorter={(a, b) => a.name.localeCompare(b.key)}
                />
                <Column
                    title="Delegation"
                    key="delegation"
                    render={(record) => (
                        <>
                            <ComputerUserElement computer={record} />
                            <ComputerDelegationElement computer={record} />
                        </>
                    )}
                    sorter={(a, b) => {
                        if (!a.invalid) return 1;
                        if (!b.invalid) return -1;

                        return differenceInSeconds(new Date(a.invalid), new Date(b.invalid));
                    }}
                />
                <Column
                    title="Installed"
                    dataIndex="installDate"
                    key="installDate"
                    defaultSortOrder="descend"
                    sorter={(a, b) => {
                        if (!a.valid) return 1;
                        if (!b.valid) return -1;

                        return differenceInSeconds(new Date(a.valid), new Date(b.valid));
                    }}
                />
                <Column
                    title="Status"
                    key="progress"
                    render={(record) => (
                        <ProgressElement
                            percentage={record.installPercentage}
                            message={record.statusNote ? record.statusNote : record.status}
                        />
                    )}
                    sorter={(a, b) => {
                        if (!a.installPercentage) return -1;
                        if (!b.installPercentage) return 1;

                        return a.installPercentage - b.installPercentage;
                    }}
                />
                <Column title="Action" key="action" render={(record) => <ComputerDropdown computer={record} />} />
            </Table>
        </>
    );
}

ComputerTable.propTypes = {
    computers: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ComputerTable;
