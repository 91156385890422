import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { Form, Input, Button, Alert, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";

// Styling
import logo from "assets/images/eth_logo_black.svg";

import AuthenticationService from "utils/authentication";
import api from "api";

const useStyles = createUseStyles({
    imageWrapper: {
        textAlign: "center",
        margin: "20px auto 40px",
    },
    formWrapper: {
        width: "368px",
        margin: "0 auto",
        padding: "140px 0 0",
        flex: "1 1",
    },
    loginButton: { width: "100%" },
});

function LoginForm({ originUrl }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(false);

    const usernameRef = useRef(null);
    const history = useHistory();
    const classes = useStyles();
    const queryClient = useQueryClient();

    useEffect(() => usernameRef.current.focus(), []);

    const handleLogin = async () => {
        const request = { username, password };
        try {
            const { data } = await api.getUser(request);
            const { admin } = data;
            // Use username, password from request in case user changed input (and therefore state) since login call
            AuthenticationService.setAuthentication(request.username, request.password, admin);
            message.success("Sucessfully logged in");
            // Invalidate all queries because most of them are user-dependent
            queryClient.invalidateQueries();

            if (originUrl) {
                history.push(originUrl);
            } else {
                history.push("/");
            }
        } catch {
            setLoginError(true);
        }
    };

    return (
        <div className={classes.formWrapper}>
            <div className={classes.imageWrapper}>
                <img src={logo} className="image" alt="ETH Logo" />
            </div>
            <Form className="login-form" onFinish={handleLogin}>
                <Form.Item>
                    <Input
                        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder="Username"
                        value={username}
                        name="username"
                        size="large"
                        onChange={(event) => setUsername(event.target.value)}
                        ref={usernameRef}
                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                        value={password}
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="large"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className={classes.loginButton} size="large">
                        Log in
                    </Button>
                </Form.Item>
                {loginError && <Alert message="Login failed" type="error" showIcon />}
            </Form>
        </div>
    );
}

LoginForm.propTypes = {
    originUrl: PropTypes.string,
};

LoginForm.defaultProps = {
    originUrl: undefined,
};

export default LoginForm;
