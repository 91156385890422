import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Layout, Menu, Button, Dropdown, message } from "antd";
import {
    PoweroffOutlined,
    DesktopOutlined,
    SolutionOutlined,
    DeploymentUnitOutlined,
    CodeOutlined,
    DownOutlined,
    PlusOutlined,
    UserOutlined,
} from "@ant-design/icons";

import { ICON_PATH } from "configuration";
import AuthenticationService from "utils/authentication";

const { Header, Content, Sider, Footer } = Layout;

function PageLayout({ children, page }) {
    const authentication = AuthenticationService.getAuthentication();
    const history = useHistory();

    const handleUserMenuClick = (event) => {
        if (event.key === "logout") {
            AuthenticationService.deleteAuthentication();
            history.push("/login");
            message.success("Signed out");
        }
    };

    const menu = (
        <Menu onClick={handleUserMenuClick}>
            <Menu.Item key="logout">
                <PoweroffOutlined />
                Log out
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout>
            <Sider breakpoint="lg" collapsedWidth="0" width="250">
                <img src={`${process.env.PUBLIC_URL}/${ICON_PATH}`} alt="Logo" />

                <Menu theme="dark" mode="inline" style={{ textAlign: "left" }} defaultSelectedKeys={[page]}>
                    <Menu.Item key="computers">
                        <DesktopOutlined />
                        <Link to="/" className="nav-text" style={{ display: "inline" }}>
                            Computers
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="form">
                        <SolutionOutlined />
                        <Link to="/form" className="nav-text" style={{ display: "inline" }}>
                            Form
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="template">
                        <DeploymentUnitOutlined />
                        <Link to="/templates" className="nav-text" style={{ display: "inline" }}>
                            Templates
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="api">
                        <CodeOutlined />
                        <Link to="/api" className="nav-text" style={{ display: "inline" }}>
                            API
                        </Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout>
                <Header style={{ background: "#fff", header: "50px", paddingLeft: "24px", paddingRight: "24px" }}>
                    {page !== "form" && (
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            shape="round"
                            onClick={() => history.push("/form")}
                        >
                            Computer
                        </Button>
                    )}

                    {authentication ? (
                        <div style={{ float: "right" }}>
                            <Dropdown overlay={menu}>
                                <Button icon={<UserOutlined />}>
                                    {authentication.username} <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    ) : (
                        <div style={{ float: "right" }}>
                            <Button icon={<UserOutlined />} onClick={() => history.push("/login")}>
                                Login
                            </Button>
                        </div>
                    )}
                </Header>
                <Content style={{ padding: "24px 24px 0", minHeight: "calc(100vh - 134px" }}>
                    <div>{children}</div>
                </Content>
                <Footer style={{ textAlign: "center" }}>
                    <div>
                        Developed by <a href="https://www.isg.inf.ethz.ch/">ISGINF</a> - {new Date().getFullYear()}
                        <a href="https://www.ethz.ch/de.html"> ETH Zürich</a>
                    </div>
                </Footer>
            </Layout>
        </Layout>
    );
}

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.string.isRequired,
};

export default PageLayout;
