import { differenceInHours, format, getYear, formatDistanceToNow } from "date-fns";

// Get progress color for every percentage bracked
const getStatusColor = (percentage) => {
    if (percentage < 5) {
        return "#ffb2a4";
    }
    if (percentage < 10) {
        return "#feb6a8";
    }
    if (percentage < 15) {
        return "#fdbda7";
    }
    if (percentage < 20) {
        return "#fdc3a6";
    }
    if (percentage < 25) {
        return "#fec1a6";
    }
    if (percentage < 30) {
        return "#fcd3a2";
    }
    if (percentage < 35) {
        return "#fbd9a1";
    }
    if (percentage < 40) {
        return "#fbdda1";
    }
    if (percentage < 45) {
        return "#fbe0a1";
    }
    if (percentage < 50) {
        return "#faeb9f";
    }
    if (percentage < 55) {
        return "#f9f79c";
    }
    if (percentage < 60) {
        return "#e9f298";
    }
    if (percentage < 65) {
        return "#dcec95";
    }
    if (percentage < 70) {
        return "#d0e692";
    }
    if (percentage < 75) {
        return "#d2e893";
    }
    if (percentage < 80) {
        return "#c9e491";
    }
    if (percentage < 85) {
        return "#bade8d";
    }
    if (percentage < 90) {
        return "#add889";
    }
    if (percentage < 95) {
        return "#a7d688";
    }
    if (percentage < 100) {
        return "#a5d588";
    }
    return "#93cd83";
};

// Format a creation date
const getWhenAgoInstalled = (date) => {
    const computerInstalledDate = new Date(date);
    const currentDate = new Date();

    // Time difference between when the computer installation was started and current time
    const whenAgoInstalled = differenceInHours(currentDate, computerInstalledDate);

    // If computer has been installed less than 24 hours ago, show specific moment text
    // If computer has been installed more than 24 hours ago, show day and month of installation date
    if (whenAgoInstalled < 24) {
        return formatDistanceToNow(computerInstalledDate, { addSuffix: true });
    }

    if (getYear(computerInstalledDate) !== getYear(currentDate)) {
        return format(computerInstalledDate, "dd MMM yyyy");
    }

    return format(computerInstalledDate, "dd MMM");
};

export { getStatusColor, getWhenAgoInstalled };
