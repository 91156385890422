import { Divider } from "antd";
import { createUseStyles } from "react-jss";

import PageLayout from "components/pages/PageLayout";
import ComputerForm from "components/form/ComputerForm";
import DelegationForm from "components/form/DelegationForm";
import ComputerNameInput from "components/form/ComputerNameInput";

const useStyles = createUseStyles({
    wrapper: { background: "#fff" },
});

function FormPage() {
    const classes = useStyles();

    return (
        <PageLayout page="form">
            <div className={classes.wrapper}>
                <ComputerNameInput />
                <Divider />
                <ComputerForm />
                <Divider />
                <DelegationForm />
            </div>
        </PageLayout>
    );
}

export default FormPage;
