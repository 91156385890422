import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Input, Form, message } from "antd";

import { useDebounce } from "hooks/debounce";
import { useComputerNameVallidation } from "hooks/validateComputerName";

function InputModal({ visible, onCancel, onOk, title, loading }) {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (!visible) setValue("");
    }, [visible]);

    const handleOk = () => {
        onOk(value);
    };

    const handleClose = () => {
        onCancel();
    };

    return (
        <Modal
            visible={visible}
            title={title}
            okText="Save"
            onCancel={handleClose}
            onOk={handleOk}
            confirmLoading={loading}
        >
            <Input value={value} onChange={(event) => setValue(event.target.value)} />
        </Modal>
    );
}

function ValidationModal({ visible, onCancel, onOk, title, loading }) {
    const [value, setValue] = useState("");

    const debouncedValue = useDebounce(value, 300);
    const { status, message: help } = useComputerNameVallidation(debouncedValue);

    const handleOk = () => {
        if (value === "") return message.warn("Field cannot be empty");
        if (status !== "success") return message.warn("Validation unsuccessful");

        onOk(value);
        setValue("");
    };

    const handleClose = () => {
        onCancel();
        setValue("");
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setValue(value);
    };

    return (
        <Modal
            visible={visible}
            title={title}
            okText="Save"
            onCancel={handleClose}
            onOk={handleOk}
            confirmLoading={loading}
        >
            <Form.Item hasFeedback validateStatus={status} help={help}>
                <Input value={value} onChange={handleChange} />
            </Form.Item>
        </Modal>
    );
}

const ConfirmModal = ({ visible, onCancel, onOk, title, loading, text }) => (
    <Modal visible={visible} title={title} okType="danger" confirmLoading={loading} onCancel={onCancel} onOk={onOk}>
        {text ? <p>{text}</p> : <p>Click the Ok button to confirm</p>}
    </Modal>
);

function CustomModal(props) {
    const { type } = props;

    if (type === "input") {
        return <InputModal {...props} />;
    }

    if (type === "validation") {
        return <ValidationModal {...props} />;
    }

    if (type === "confirm") {
        return <ConfirmModal {...props} />;
    }

    return null;
}

const props = {
    type: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    loading: PropTypes.bool,
};

const defaultProps = {
    title: "",
    text: "",
    loading: false,
};

CustomModal.propTypes = props;
CustomModal.defaultProps = props;
InputModal.propTypes = props;
InputModal.defaultProps = defaultProps;
ValidationModal.propTypes = props;
ValidationModal.defaultProps = defaultProps;
ConfirmModal.propTypes = props;
ConfirmModal.defaultProps = defaultProps;

export default CustomModal;
