import { Typography } from "antd";

// Utils
import { CLI_URL, CONTACT_URL, CONTACT_NAME } from "configuration";

const { Title } = Typography;

function DocumentationText() {
    return (
        <div>
            <Title level={2}>API v1</Title>
            <Title level={3}>General</Title>
            <p>
                The API follows the principles of{" "}
                <a href="https://en.wikipedia.org/wiki/REST" target="_blank" rel="noreferrer noopener">
                    REST
                </a>
                . A simple script for convenience (wrapper around{" "}
                <a href="https://httpie.org/" target="_blank" rel="noreferrer noopener">
                    HTTPie
                </a>{" "}
                in bash) is available here:{" "}
                <a href={CLI_URL} download="oskiosk.sh">
                    oskiosk.sh
                </a>
            </p>

            <Title level={4}>Authentication</Title>
            <p>
                Use{" "}
                <a href="https://en.wikipedia.org/wiki/Basic_authentication" target="_blank" rel="noreferrer noopener">
                    HTTP Basic authentication
                </a>{" "}
                to authenticate against the server (available over TLS/SSL only).
            </p>

            <Title level={4}>Authorization</Title>
            <p>
                Every user is allowed to call the functions below, but the results differ, depending on the access
                rights the user has. Some functions are even accessible anonymously, but you still need to authenticate.
                For anonymous access, the user{" "}
                <b>
                    <tt>anonymous</tt>
                </b>{" "}
                with the same password (<tt>anonymous</tt>) can be used.
            </p>
            <p>
                If you need a login token for automatically running scripts, please open a ticket at{" "}
                <a href={CONTACT_URL} rel="noreferrer noopener">
                    {CONTACT_NAME}
                </a>{" "}
                and we will create one for you.
            </p>

            <Title level={4}>Media Types</Title>
            <p>
                The API supports two media types:{" "}
                <a href="https://en.wikipedia.org/wiki/JSON" target="_blank" rel="noreferrer noopener">
                    JSON
                </a>{" "}
                and{" "}
                <a href="https://en.wikipedia.org/wiki/Percent-encoding" target="_blank" rel="noreferrer noopener">
                    HTTP
                </a>
                . Default is JSON.
            </p>
            <p>
                To specify a media type for body data, add the header field Content-Type (
                <tt>
                    Content-Type: <b>application/json</b>
                </tt>{" "}
                or{" "}
                <tt>
                    Content-Type: <b>application/x-www-form-urlencoded</b>
                </tt>
                ). To specify a media type for response data, add one of the same strings to the header field Accept
                (e.g. <tt>Accept: application/json</tt>).
            </p>

            <Title level={4}>Error Handling</Title>
            <p>
                The status of a function call will be passed as{" "}
                <a
                    href="https://en.wikipedia.org/wiki/List_of_HTTP_status_codes"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    HTTP status code
                </a>
                , so all{" "}
                <b>
                    <tt>
                        2<i>xx</i>
                    </tt>
                </b>{" "}
                codes mean success,{" "}
                <b>
                    <tt>
                        4<i>xx</i>
                    </tt>
                </b>{" "}
                means client error and{" "}
                <b>
                    <tt>
                        5<i>xx</i>
                    </tt>
                </b>{" "}
                means server error. For more details, see the link above.
            </p>

            <Title level={3}>Functions</Title>
            <p>
                All the values in brackets are{" "}
                <a href="https://en.wikipedia.org/wiki/Regular_expression" target="_blank" rel="noreferrer noopener">
                    regular expressions
                </a>{" "}
                for parameters that must be passed through the function. All GET parameters (everything after the
                question mark) are optional.
            </p>
        </div>
    );
}

export default DocumentationText;
