import PropTypes from "prop-types";
import { Form } from "antd";

const MissingOption = ({ option }) => (
    <Form.Item validateStatus="error" help="This option does not exist anymore!">
        <p>
            {option.display}: &quot;{option.value.toString()}&quot;
        </p>
    </Form.Item>
);

MissingOption.propTypes = {
    option: PropTypes.object.isRequired,
};

export default MissingOption;
