import { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import useFormStore from "hooks/useFormStore";

const { TextArea } = Input;

const tailLayout = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 },
    },
};

const getAttributes = (error) => {
    if (!error) return {};

    return {
        validateStatus: "error",
        help: "This value is invalid",
    };
};

function MultitextFormOption({ option }) {
    const initialValue = useFormStore((state) => state.optionValues)[option.name];
    const updateOptionValue = useFormStore((state) => state.updateOptionValue);
    const finishOptionChange = useFormStore((state) => state.finishOptionChange);

    const [value, setValue] = useState(initialValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        updateOptionValue(option.name, event.target.value);
    };

    // Gets called for an input that modifys and state and validates the input
    const handleFinishedChange = (event) => {
        finishOptionChange(option.name, event.target.value);
    };

    return (
        <>
            <Form.Item label={option.display} {...getAttributes()}>
                <TextArea rows={4} value={value} onChange={handleChange} onBlur={handleFinishedChange} />
                <Upload
                    accept=".txt, .csv"
                    showUploadList={false}
                    beforeUpload={(file) => {
                        const reader = new FileReader();
                        reader.onload = (event) => setValue(event.target.result);
                        reader.readAsText(file);
                        return false;
                    }}
                >
                    <Button>
                        <UploadOutlined /> Click to Upload
                    </Button>
                </Upload>
            </Form.Item>
            {option.description && (
                <Form.Item {...tailLayout}>
                    <p>{option.description}</p>
                </Form.Item>
            )}
        </>
    );
}

MultitextFormOption.propTypes = {
    option: PropTypes.object.isRequired,
};

export default MultitextFormOption;
