import { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";

import { Menu, Button, Dropdown, message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import CustomModal from "components/utils/CustomModal";
import { defaultErrorHandler } from "utils/errorHandling";

import api from "api";

function ComputerMenuElement({ computer }) {
    const history = useHistory();
    const queryClient = useQueryClient();

    const [visible, setVisible] = useState(false);
    const [modal, setModal] = useState({
        title: "",
        text: "",
        type: "",
        onOk: () => {},
        onCancel: () => setVisible(false),
    });

    const { mutate: dispatchReinstall, status: reinstallStatus } = useMutation(
        ({ request }) => api.saveComputer(request),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries("computers");
                message.success("Reinstall sucessfully scheduled. Please rebooot to initiate.");
                history.push(`/computers/${variables.name}`);
            },
            onError: defaultErrorHandler,
            onSettled: () => setVisible(false),
        },
    );

    const reinstall = async () => {
        const request = {
            name: computer.name,
            template: computer.template.id,
        };

        dispatchReinstall({ request });
    };

    const clickHandler = ({ key }) => {
        switch (key) {
            case "immediate-reinstall": {
                setModal({
                    ...modal,
                    title: `Do you want to reinstall ${computer.name}?`,
                    text: `Do you really want to reinstall ${computer.name} with the current options immediately? All local data may be lost, depending on the partitioning you selected!`,
                    onOk: reinstall,
                    type: "confirm",
                });
                setVisible(true);
                break;
            }
            case "template":
                history.push(`/form/template/${computer.template.id}`);
                break;
            default:
                message.error("Unknown action");
        }
    };

    return (
        <>
            <Menu onClick={clickHandler}>
                <Menu.Item key="template">Use as Template</Menu.Item>
                <Menu.Item key="immediate-reinstall">Immediate Reinstall</Menu.Item>
            </Menu>
            <CustomModal {...modal} loading={reinstallStatus === "loading"} visible={visible} />
        </>
    );
}

function ComputerHistoryDropdown({ computer }) {
    const menu = <ComputerMenuElement computer={computer} />;

    return (
        <Dropdown overlay={menu}>
            <Button shape="circle" icon={<MenuOutlined />} />
        </Dropdown>
    );
}

const props = {
    computer: PropTypes.object.isRequired,
};

ComputerHistoryDropdown.propTypes = props;
ComputerMenuElement.propTypes = props;

export default ComputerHistoryDropdown;
