import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Card, Space } from "antd";
import { SolutionOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";

import OperatingSystemLogo from "components/util-components/OperatingSystemLogo";
import ComputerDelegationElement from "components/computers/ComputerDelegationElement";
import ComputerUserElement from "components/computers/ComputerUserElement";
import ComputerDropdown from "components/computers/ComputerDropdown";
import ProgressElement from "components/util-components/ProgressElement";
import ComputerName from "components/util-components/ComputerName";

const { Meta } = Card;

const useStyles = createUseStyles({
    card: { width: "100%", marginTop: 12 },
});

function ComputerCard({ computer }) {
    const history = useHistory();
    const classes = useStyles();

    const os = computer.hasComputer ? (
        <OperatingSystemLogo os={computer.os.name} display="Message" />
    ) : (
        <OperatingSystemLogo os="none" />
    );

    return (
        <Card
            className={classes.card}
            actions={[
                <SolutionOutlined key="install" onClick={() => history.push(`/form/computer/${computer.name}`)} />,
                <ComputerDropdown computer={computer} key="dropdown" />,
            ]}
        >
            <Space direction="vertical">
                <Meta avatar={os} title={<ComputerName name={computer.name} />} />
                <span>{computer.hasComputer ? `Installed ${computer.installDate}` : "Not yet installed"}</span>
                {computer.installPercentage >= 0 && (
                    <ProgressElement
                        percentage={computer.installPercentage}
                        message={computer.statusNote ? computer.statusNote : computer.status}
                    />
                )}
                <div>
                    <ComputerUserElement computer={computer} />
                    <ComputerDelegationElement computer={computer} />
                </div>
            </Space>
        </Card>
    );
}

ComputerCard.propTypes = {
    computer: PropTypes.object.isRequired,
};

export default ComputerCard;
