import { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";

import { Menu, Button, Dropdown, message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import CustomModal from "components/utils/CustomModal";
import { defaultErrorHandler } from "utils/errorHandling";

import api from "api";

function ComputerMenuElement({ computer }) {
    const history = useHistory();
    const queryClient = useQueryClient();

    const [modal, setModal] = useState({
        title: "",
        text: "",
        type: "",
        onOk: () => {},
        onCancel: () => setVisible(false),
    });

    const [visible, setVisible] = useState(false);

    const { mutate: dispatchRevokeDelegation, status: revokeDelegationStatus } = useMutation(
        ({ id }) => api.deleteDelegation(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("delegations");
                message.success(`${computer.name} delegation deleted`);
            },
            onError: defaultErrorHandler,
            onSettled: () => setVisible(false),
        },
    );

    const { mutate: dispatchAbortInstallation, status: abortInstallationStatus } = useMutation(
        ({ name, request }) => api.setComputerStatus(name, request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("computers");
                message.success(`${computer.name} installation finished / aborted`);
            },
            onError: defaultErrorHandler,
            onSettled: () => setVisible(false),
        },
    );

    const { mutate: dispatchDeleteComputer, status: deleteComputerStatus } = useMutation(
        ({ name }) => api.deleteComputer(name),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("computers");
                message.success(`${computer.name} deleted`);
            },
            onError: defaultErrorHandler,
            onSettled: () => setVisible(false),
        },
    );

    const { mutate: dispatchReinstall, status: reinstallStatus } = useMutation(
        ({ request }) => api.saveComputer(request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("computers");
                message.success("Reinstall sucessfully scheduled. Please rebooot to initiate.");
                history.push(`/`);
            },
            onError: defaultErrorHandler,
            onSettled: () => setVisible(false),
        },
    );

    const loading =
        reinstallStatus === "loading" ||
        deleteComputerStatus === "loading" ||
        abortInstallationStatus === "loading" ||
        revokeDelegationStatus === "loading";

    const revokeDelegation = async () => {
        dispatchRevokeDelegation({ id: computer.delegationId });
    };

    const abortInstallation = async (reason) => {
        const request = {
            percent: 100,
            installationFinishNote: reason,
        };

        dispatchAbortInstallation({ name: computer.name, request });
    };

    const deleteComputer = async () => {
        dispatchDeleteComputer({ name: computer.name });
    };

    const reinstall = async () => {
        const request = {
            name: computer.name,
            template: computer.templateId,
        };

        dispatchReinstall({ request });
    };

    const clickHandler = ({ key }) => {
        switch (key) {
            case "revoke-delegation": {
                setModal({
                    ...modal,
                    title: `Do you want to delete delegation?`,
                    text: `Click the Ok button to confirm deleting delegation for ${computer.name}`,
                    onOk: revokeDelegation,
                    type: "confirm",
                });
                setVisible(true);
                break;
            }
            case "abort-finish": {
                setModal({
                    ...modal,
                    title: `Abort / Finish installation for ${computer.name}?`,
                    onOk: abortInstallation,
                    type: "input",
                });
                setVisible(true);
                break;
            }
            case "delete-computer": {
                setModal({
                    ...modal,
                    title: `Do you want to delete ${computer.name}?`,
                    text: "Click the Ok button to confirm computer deletion",
                    onOk: deleteComputer,
                    type: "confirm",
                });
                setVisible(true);
                break;
            }
            case "display-history":
                history.push(`/computers/${computer.name}/history`);
                break;
            case "install-delegate":
            case "install":
                history.push(`/form/computer/${computer.name}`);
                break;
            case "immediate-reinstall": {
                setModal({
                    ...modal,
                    title: `Do you want to reinstall ${computer.name}?`,
                    text: `Do you really want to reinstall ${computer.name} with the current options immediately? All local data may be lost, depending on the partitioning you selected!`,
                    onOk: reinstall,
                    type: "confirm",
                });
                setVisible(true);
                break;
            }
            case "template":
                history.push(`/form/template/${computer.templateId}`);
                break;
            default:
                message.error("Unknown action");
        }
    };

    const modalElement = <CustomModal {...modal} loading={loading} visible={visible} />;

    // Delegation but no computer / template
    if (computer.hasDelegation && !computer.hasComputer) {
        return (
            <>
                <Menu onClick={clickHandler}>
                    <Menu.Item key="install">Install</Menu.Item>
                    <Menu.Item key="revoke-delegation">Revoke delegation</Menu.Item>
                </Menu>
                {modalElement}
            </>
        );
    }

    // No delegation and only a computer / template
    if (!computer.hasDelegation && computer.hasComputer) {
        return (
            <>
                <Menu onClick={clickHandler}>
                    <Menu.Item key="display-history">Display History</Menu.Item>
                    <Menu.Item key="template">Use as Template</Menu.Item>
                    <Menu.Item key="immediate-reinstall">Immediate Reinstall</Menu.Item>
                    <Menu.Item key="install">Install / Delegate</Menu.Item>
                    {computer.installPercentage !== 100 && <Menu.Item key="abort-finish">Abort / Finish</Menu.Item>}
                    <Menu.Item key="delete-computer">Delete Computer</Menu.Item>
                </Menu>
                {modalElement}
            </>
        );
    }

    // Computer and delegation and installation finished
    return (
        <>
            <Menu onClick={clickHandler}>
                <Menu.Item key="display-history">Display History</Menu.Item>
                <Menu.Item key="template">Use as Template</Menu.Item>
                <Menu.Item key="immediate-reinstall">Immediate Reinstall</Menu.Item>
                <Menu.Item key="install">Install / Delegate</Menu.Item>
                <Menu.Item key="revoke-delegation">Revoke Delegation</Menu.Item>
                {computer.installPercentage !== 100 && <Menu.Item key="abort-finish">Abort / Finish</Menu.Item>}
                <Menu.Item key="delete-computer">Delete Computer</Menu.Item>
            </Menu>
            {modalElement}
        </>
    );
}

function ComputerDropdown({ computer }) {
    const menu = <ComputerMenuElement computer={computer} />;

    return (
        <Dropdown overlay={menu}>
            <Button shape="circle" icon={<MenuOutlined />} />
        </Dropdown>
    );
}

const props = {
    computer: PropTypes.object.isRequired,
};

ComputerMenuElement.propTypes = props;
ComputerDropdown.propTypes = props;

export default ComputerDropdown;
