export const HTTP_CODES = {
    200: "OK",
    201: "Created",
    204: "No Content",
    400: "Bad Request",
    403: "Forbidden",
    404: "Not Found",
};

export const QUERY_CONFIG = {
    cacheTime: 1000 * 60 * 15,
    staleTime: 1000 * 60,
    retry: 1,
};
