import { useMemo } from "react";
import { useQuery } from "react-query";
import { createUseStyles } from "react-jss";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { format, isAfter, isBefore, isEqual } from "date-fns";

import ProgressElement from "components/util-components/ProgressElement";
import ComputerHistoryDropdown from "components/computer-history/ComputerHistoryDropdown";
import ComputerName from "components/util-components/ComputerName";
import NamedOS from "components/util-components/NamedOS";
import { defaultErrorHandler } from "utils/errorHandling";
import { QUERY_CONFIG } from "constants.js";
import api from "api";

const { Column } = Table;

const useStyles = createUseStyles({
    table: { background: "#fff" },
});

const createComputerHistoryList = (computerHistory, computerStatus) => {
    if (!computerHistory || !computerStatus) return [];

    const elementList = [];

    // Go through each computer history element
    computerHistory.forEach((computer, index) => {
        const currentValidDate = new Date(computer.valid);
        const nextValidDate =
            computerHistory.length > index + 1 ? new Date(computerHistory[index + 1].valid) : undefined;

        elementList.push({
            ...computer,
            key: nanoid(),
            type: "computer",
            installDate: format(currentValidDate, "dd.MM.yyyy HH:mm"),
        });

        // Go through all the computer statuses
        computerStatus.forEach((status) => {
            // Only add the status element to a computer / template when it actually belongs to the computer
            // A status element belongs to a computer when:
            // its creation date is after the current computer's creation date and before the next computer's creation date
            // OR the current computer is the last computer (so there is no next date)
            // then the date just has to be after the current computer's creation date
            const validDate = new Date(status.valid);

            const isSameOrAfterCurrentDate =
                isEqual(validDate, currentValidDate) || isAfter(validDate, currentValidDate);
            const isBetweenCurrentAndNextDate = isSameOrAfterCurrentDate && isBefore(validDate, nextValidDate);

            if (isBetweenCurrentAndNextDate || (nextValidDate === undefined && isSameOrAfterCurrentDate)) {
                elementList.push({
                    ...status,
                    key: nanoid(),
                    type: "status",
                    installDate: format(validDate, "dd.MM.yyyy HH:mm"),
                });
            }
        });
    });

    return elementList;
};

const loadComputerHistory = async (computer) => {
    const { data } = await api.getComputerHistory(computer);
    return data;
};

const loadStatusHistory = async (computer) => {
    const { data } = await api.getStatusHistory(computer);
    return data;
};

function ComputerHistoryList() {
    const classes = useStyles();
    const { computer } = useParams();

    const {
        data: computerHistory,
        error: computerHistoryError,
        status: computerHistoryStatus,
    } = useQuery(["computer/history", computer], () => loadComputerHistory(computer), QUERY_CONFIG);

    const {
        data: statusHistory,
        error: statusHistoryError,
        status: statusHistoryStatus,
    } = useQuery(["status/history", computer], () => loadStatusHistory(computer), QUERY_CONFIG);

    if (computerHistoryStatus === "error") defaultErrorHandler(computerHistoryError);
    if (statusHistoryStatus === "error") defaultErrorHandler(statusHistoryError);

    const loading = computerHistoryStatus === "loading" || statusHistoryStatus === "loading";

    const computerHistoryElements = useMemo(
        () => createComputerHistoryList(computerHistory, statusHistory),
        [computerHistory, statusHistory],
    );

    return (
        <Table
            pagination={false}
            dataSource={computerHistoryElements}
            loading={loading}
            className={classes.table}
            scroll={{ x: "max-content" }}
        >
            <Column
                title="OS"
                key="os"
                render={(record) => {
                    if (record.type !== "computer") return null;
                    return <NamedOS os={record.template.os} />;
                }}
            />
            <Column
                title="Computer"
                key="name"
                render={(record) => {
                    if (record.type === "computer") return <ComputerName name={record.name} />;

                    return (
                        <ProgressElement
                            percentage={record.percent}
                            message={record.note == null ? record.message : record.note}
                        />
                    );
                }}
            />
            <Column title="IP" dataIndex="ip" key="ip" />
            <Column title="Installation / Status" dataIndex="installDate" key="installDate" />
            <Column
                title="Action"
                key="action"
                render={(record) => {
                    if (record.type !== "computer") return null;
                    return <ComputerHistoryDropdown computer={record} />;
                }}
            />
        </Table>
    );
}

export default ComputerHistoryList;
