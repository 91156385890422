import PropTypes from "prop-types";

import { API_URL } from "configuration";
import { HTTP_CODES } from "constants.js";

import JSONDisplay from "components/utils/JSONDisplay";

function Example({ example, method }) {
    return (
        <>
            <p>
                $ curl -X {method} -u $USER {API_URL}/{example.request.url}
                {example.request.data ? " \\" : ""}
            </p>
            {example.request.data && (
                <>
                    <p>-H &apos;Content-Type: application/json&apos; \</p>
                    <p>-d &apos;{JSON.stringify(example.request.data)}&apos;</p>
                </>
            )}
            <p>
                HTTP/1.1 {example.response.status} {HTTP_CODES[example.response.status]}
            </p>
            {example.response.data && <JSONDisplay data={example.response.data} />}
        </>
    );
}

Example.propTypes = {
    example: PropTypes.object.isRequired,
    method: PropTypes.string.isRequired,
};

export default Example;
