import PropTypes from "prop-types";

const ComputerName = ({ name }) => (
    <span>
        <span style={{ fontWeight: "bold" }}>{name.split(".")[0]}</span>.{name.split(".").slice(1).join(".")}
    </span>
);

ComputerName.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ComputerName;
