import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Services / Utils
import api from "api";

function FormPage(props) {
    const { history } = props;

    api.getIp().then((response) => {
        if (response.data.hostname) {
            history.push(`computer/${response.data.hostname}`);
        }
    });

    return null;
}

FormPage.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(FormPage);
