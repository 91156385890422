import { Redirect, useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";

import LoginForm from "components/login-form/LoginForm";
import Footer from "components/util-components/Footer";
import AuthenticationService from "utils/authentication";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "auto",
        background: "#f0f2f5",
    },
});

function LoginPage() {
    const classes = useStyles();
    const location = useLocation();

    const originUrl = location.state ? location.state.from.pathname : undefined;

    if (AuthenticationService.getAuthentication()) {
        return <Redirect to={originUrl} />;
    }

    return (
        <div className={classes.container}>
            <LoginForm originUrl={originUrl} />
            <Footer />
        </div>
    );
}

export default LoginPage;
