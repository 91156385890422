import { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Tooltip, Button, message } from "antd";
import { formatISO } from "date-fns";

import CustomModal from "components/utils/CustomModal";
import { defaultErrorHandler } from "utils/errorHandling";

import api from "api";

const updateUserPermission = async ({ request, delegationId }) => {
    await api.deleteDelegation(delegationId);
    return api.saveDelegation(request);
};

function DelegatedUserElement({ computer }) {
    const [visible, setVisible] = useState(false);
    const queryClient = useQueryClient();

    const { mutate, status } = useMutation(updateUserPermission, {
        onSuccess: () => {
            queryClient.invalidateQueries("delegations");
            message.success(`${computer.name} delegation updated`);
            setVisible(false);
        },
        onError: (error) => {
            defaultErrorHandler(error);
            setVisible(false);
        },
    });

    // if there is no delegation available, render nothing
    if (!computer.hasDelegation) {
        return null;
    }

    const handlePermissionChange = () => {
        const request = {
            computer: computer.name,
            user: computer.user,
            root: !computer.root,
            invalid: computer.invalid ? formatISO(new Date(computer.invalid)) : null,
        };

        mutate({ request, delegationId: computer.delegationId });
    };

    const text = `Do you really want to ${computer.root ? "DISALLOW" : "ALLOW"} ${
        computer.user
    } to become root/Administrator on ${computer.name}?
    This will only take effect on new installations!`;

    const modalConfig = {
        title: `${computer.root ? "DISALLOW" : "ALLOW"} root/Administrator for ${computer.user}`,
        text,
        type: "confirm",
        onOk: () => handlePermissionChange(),
        onCancel: () => {
            setVisible(false);
        },
    };

    return (
        <>
            <Tooltip
                mouseEnterDelay={0.5}
                title={
                    computer.root
                        ? "Can become root / Administrator (click to toggle)"
                        : "Can NOT become root / Administrator (click to toggle)"
                }
            >
                <Button
                    onClick={() => setVisible(true)}
                    type={computer.root ? "primary" : "default"}
                    size="small"
                    icon={computer.root ? <UnlockOutlined /> : <LockOutlined />}
                >
                    {computer.user}
                </Button>
            </Tooltip>
            <CustomModal {...modalConfig} loading={status === "loading"} visible={visible} />
        </>
    );
}

DelegatedUserElement.propTypes = {
    computer: PropTypes.object.isRequired,
};

export default DelegatedUserElement;
