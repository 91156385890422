/* eslint-disable */

import { Route, Redirect } from "react-router-dom";

import AuthenticationService from "utils/authentication";

export default ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            AuthenticationService.getAuthentication() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
        }
    />
);
