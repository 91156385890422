import ReactDOM from "react-dom";
import { useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Modal } from "antd";

// Components
import PrivateRoute from "routes/PrivateRoute";
import ComputerPage from "components/pages/ComputerPage";
import ComputerHistoryPage from "components/pages/ComputerHistoryPage";
import TemplatePage from "components/pages/TemplatePage";
import FormPage from "components/pages/FormPage";
import FormHostnamePage from "components/pages/FormHostnamePage";
import LoginPage from "components/pages/LoginPage";
import ApiPage from "components/pages/ApiPage";
import NoMatch from "routes/NoMatch";

import "index.css";

const queryClient = new QueryClient();

function App() {
    const location = useLocation();

    useEffect(() => {
        // listen to route change
        Modal.destroyAll();
    }, [location]);

    return (
        <Switch>
            <PrivateRoute exact path="/" component={ComputerPage} />
            <PrivateRoute exact path="/computers" component={ComputerPage} />
            <PrivateRoute exact path="/computers/:computer" component={ComputerPage} />
            <PrivateRoute exact path="/computers/:computer/history" component={ComputerHistoryPage} />
            <PrivateRoute exact path="/templates" component={TemplatePage} />,
            <PrivateRoute exact path="/templates/:template" component={TemplatePage} />,
            <PrivateRoute exact path="/form" component={FormPage} />,
            <PrivateRoute exact path="/form/this" component={FormHostnamePage} />,
            <PrivateRoute exact path="/form/template/:template" component={FormPage} />,
            <PrivateRoute exact path="/form/computer/:computer" component={FormPage} />,
            <Route exact path="/api" component={ApiPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route component={NoMatch} />
        </Switch>
    );
}

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </QueryClientProvider>,
    document.getElementById("root"),
);
