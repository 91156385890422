import { useQuery } from "react-query";
import api from "api";
import { QUERY_CONFIG } from "constants.js";

const loadTemplates = async () => {
    const { data } = await api.getTemplates();
    return data;
};

const useTemplates = () => {
    const { status, data, error } = useQuery("templates", loadTemplates, QUERY_CONFIG);

    return { data: data || [], error, status };
};

export { useTemplates };
