import { CopyrightOutlined } from "@ant-design/icons";

// Styling
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    footer: {
        margin: "48px 0 24px 0",
        padding: "0 16px",
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.45)",
        fontSize: 14,
    },
    footerRow: {
        bottom: 8,
    },
});

function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <div className={classes.footerRow}>
                Developed by <a href="https://www.isg.inf.ethz.ch/">ISGINF</a>
            </div>
            <div>
                Copyright <CopyrightOutlined /> {new Date().getFullYear()} ETH Zürich
            </div>
        </footer>
    );
}

export default Footer;
