import create from "zustand";
import produce from "immer";

import api from "api";
import { defaultErrorHandler } from "utils/errorHandling";

const immer = (config) => (set, get, api) => config((fn) => set(produce(fn)), get, api);

const store = (set, get) => ({
    operatingSystem: "none",
    setOperatingSystem: (operatingSystem) =>
        set((state) => {
            state.operatingSystem = operatingSystem;
        }),

    optionValues: {},
    updateOptionValue: (name, value) =>
        set((state) => {
            state.optionValues[name] = value;
        }),

    options: [],
    setOptions: (options) =>
        set((state) => {
            state.options = options;
        }),

    missingOptions: {},
    setMissingOptions: (missingOptions) =>
        set((state) => {
            state.missingOptions = missingOptions;
        }),

    invalidOptions: [],
    setInvalidOptions: (invalidOptions) =>
        set((state) => {
            state.invalidOptions = invalidOptions;
        }),

    installComputerLoading: false,
    setInstallComputerLoading: (isLoading) =>
        set((state) => {
            state.installComputerLoading = isLoading;
        }),

    loadingOptions: [],
    addLoadingOption: (loadingOption) =>
        set((state) => {
            state.loadingOptions.push(loadingOption);
        }),

    templateSaveName: "",
    setTemplateSaveName: (templateSaveName) =>
        set((state) => {
            state.templateSaveName = templateSaveName;
        }),

    computerName: "",
    setComputerName: (computerName) =>
        set((state) => {
            state.computerName = computerName;
        }),

    computerNameValidation: {},
    setComputerNameValidation: (computerNameValidation) =>
        set((state) => {
            state.computerNameValidation = computerNameValidation;
        }),

    operatingSystems: [],
    setOperatingSystems: (operatingSystems) =>
        set((state) => {
            state.operatingSystems = operatingSystems;
        }),

    // Actions
    operatingSystemChange: (operatingSystem) =>
        set((state) => {
            state.operatingSystem = operatingSystem;
            state.options = [];
        }),
    finishOptionChange: (name, value) => {
        set((state) => {
            state.optionValues[name] = value;
            state.loadingOptions.push(name);
        });

        get().getOperatingSystemOptions();
    },
    getOperatingSystemOptions: async (operatingSystemParam, optionValuesParam, initial = false) => {
        // Missing options are for when a template gets displayed and some of the values are no longer existing options for that operating system
        const { missingOptions, testFormTemplate, computerName } = get();
        const operatingSystem = operatingSystemParam || get().operatingSystem;
        const optionValues = optionValuesParam || get().optionValues;
        const localOptionValues = { ...optionValues };

        let computer = "";

        if (computerName) {
            computer = computerName;
        }

        const requestBodyOptions = { ...optionValues, computer };

        try {
            const response = await api.getOperatingSystemOptions(operatingSystem, requestBodyOptions);

            const responseDataKeyArray = [];

            // Go trough each returned option
            // Only save default option value from fetched options if there is no input in state for this exact option
            response.data.forEach((option) => {
                if (localOptionValues[option.name] === undefined) {
                    localOptionValues[option.name] = option.default;
                }

                // Add to options array
                responseDataKeyArray.push(option.name);
            });

            // Check if optionValues has key that is not inside responseDatakeyArray and if yes remove it
            Object.keys(localOptionValues).forEach((key) => {
                if (!responseDataKeyArray.includes(key)) {
                    if (initial) {
                        missingOptions[key] = localOptionValues[key];
                    }

                    delete localOptionValues[key];
                }
            });

            set((state) => {
                state.options = response.data;
                state.optionValues = localOptionValues;
                state.missingOptions = missingOptions;
            });

            testFormTemplate(localOptionValues, operatingSystem);
        } catch (error) {
            defaultErrorHandler(error);
        }
    },
    testFormTemplate: async (optionValues, operatingSystem) => {
        // Request body for test template
        const requestBodyTest = {
            ...optionValues,
            os: operatingSystem,
        };

        try {
            const response = await api.testTemplate(requestBodyTest);
            // Check if test went successfully (based on HTTP status code)
            if (response.status === 200) {
                set((state) => {
                    state.invalidOptions = [];
                });
            }
        } catch (error) {
            if (Object.prototype.hasOwnProperty.call(error.response?.data, "invalid")) {
                set((state) => {
                    state.invalidOptions = error.response.data.invalid;
                });
            } else {
                defaultErrorHandler(error);
            }
        } finally {
            set((state) => {
                state.loadingOptions = [];
            });
        }
    },
});

export default create(immer(store));
