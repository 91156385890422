import PageLayout from "components/pages/PageLayout";
import ComputerHistoryList from "components/computer-history/ComputerHistoryList";

function ComputerHistoryPage() {
    return (
        <PageLayout page="computers">
            <ComputerHistoryList />
        </PageLayout>
    );
}

export default ComputerHistoryPage;
