import PageLayout from "components/pages/PageLayout";
import ComputerList from "components/computers/ComputerList";

function ComputerPage() {
    return (
        <PageLayout page="computers">
            <ComputerList />
        </PageLayout>
    );
}

export default ComputerPage;
