import Footer from "components/util-components/Footer";

// Styling
import logo from "assets/images/eth_logo_black.svg";
import "./NoMatch.css";

function NoMatch() {
    return (
        <div className="layout-container">
            <div className="wrapper">
                <div className="image-wrapper">
                    <img src={logo} alt="ETH Logo" />
                </div>
                <div className="error-text">
                    <h1 className="error-title">404</h1>
                    <p className="error-description">The page you visited does not exist</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default NoMatch;
