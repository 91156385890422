import { message } from "antd";

const defaultErrorHandler = (error) => {
    try {
        if (Object.prototype.hasOwnProperty.call(error.response.data, "message")) {
            message.error(error.response.data.message);
        } else {
            message.error(error.message);
        }
    } catch {
        message.error("Error has occured during this operation");
    }
};

const extractMessage = (error) => {
    try {
        if (Object.prototype.hasOwnProperty.call(error.response.data, "message")) {
            return error.response.data.message;
        }
        return error.message;
    } catch {
        return "Error has occured during this operation";
    }
};

const validationSuccess = (validation) => {
    if (Object.entries(validation).length === 0) return true;
    if (validation.constructor !== Object) return true;
    if (validation.validateStatus !== "error") return true;

    return false;
};

export { defaultErrorHandler, extractMessage, validationSuccess };
