import { useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Table, Button } from "antd";
import { createUseStyles } from "react-jss";
import { differenceInSeconds } from "date-fns";

import TemplateDropdown from "components/template/TemplateDropdown";
import NamedOS from "components/util-components/NamedOS";
import { useTemplates } from "hooks/template";
import { getWhenAgoInstalled } from "utils/genericUtils";
import { defaultErrorHandler } from "utils/errorHandling";

const { Column } = Table;

const useStyles = createUseStyles({
    button: { marginTop: 20, display: "block", marginLeft: "auto", marginRight: 0 },
    table: {
        background: "#fff",
        "& .table-highlight": { backgroundColor: "#95de64", "&:hover": { backgroundColor: "transparent" } },
    },
});

const createTemplateList = (templates) => {
    if (!templates) return [];
    return templates.map((template) => ({
        key: template.id,
        installDate: getWhenAgoInstalled(template.created),
        ...template,
    }));
};

function TemplateList() {
    const { data: templates, status, error } = useTemplates();

    const { template } = useParams();
    const history = useHistory();
    const classes = useStyles();

    if (status === "error") {
        defaultErrorHandler(error);
    }

    const templateElementsList = useMemo(() => createTemplateList(templates), [templates]);

    return (
        <div>
            <Table
                pagination={false}
                dataSource={templateElementsList}
                loading={status === "loading"}
                className={classes.table}
                rowClassName={(record) => {
                    if (record.key.toString() === template) return "table-highlight";
                }}
                scroll={{ x: "max-content" }}
            >
                <Column
                    title="Operating System"
                    key="os"
                    render={(template) => <NamedOS os={template.os} />}
                    sorter={(a, b) => a.os.display.localeCompare(b.os.display)}
                />
                <Column
                    title="Name"
                    key="name"
                    render={(template) => <span style={{ fontWeight: "bold" }}>{template.name}</span>}
                    sorter={(a, b) => a.name.localeCompare(b.name)}
                />
                <Column
                    title="Created"
                    dataIndex="installDate"
                    key="created"
                    defaultSortOrder="descend"
                    sorter={(a, b) => {
                        if (!a.created) return 1;
                        if (!b.created) return -1;

                        return differenceInSeconds(new Date(a.created), new Date(b.created));
                    }}
                />
                <Column title="Action" key="action" render={(template) => <TemplateDropdown template={template} />} />
            </Table>
            <Button type="primary" className={classes.button} onClick={() => history.push("/form")}>
                New Template
            </Button>
        </div>
    );
}

export default TemplateList;
