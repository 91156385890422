import PropTypes from "prop-types";
import { Progress, Tooltip } from "antd";
import { createUseStyles } from "react-jss";

import { getStatusColor } from "utils/genericUtils";

const MAX_MESSAGE_LENGTH = 28;

const useStyles = createUseStyles({
    progress: { marginRight: 15 },
});

const shortenMessage = (message) => {
    if (!message) return "";
    if (message.length <= MAX_MESSAGE_LENGTH) return message;

    return message.substring(0, MAX_MESSAGE_LENGTH).trim() + "...";
};

function ProgressElement({ percentage, message }) {
    const classes = useStyles();
    // Get color for current progress percentage
    const strokeColor = getStatusColor(percentage);

    const shortenedMessage = shortenMessage(message);

    return (
        <>
            <Progress
                className={classes.progress}
                type="circle"
                percent={percentage}
                width={40}
                status="normal"
                strokeColor={strokeColor}
            />
            <Tooltip mouseEnterDelay={0.5} title={message}>
                <span>{shortenedMessage}</span>
            </Tooltip>
        </>
    );
}

ProgressElement.propTypes = {
    percentage: PropTypes.number,
    message: PropTypes.string,
};

ProgressElement.defaultProps = {
    percentage: 0,
    message: "",
};

export default ProgressElement;
