import { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import api from "api";
import { createUseStyles } from "react-jss";
import { Input } from "antd";

import { getWhenAgoInstalled } from "utils/genericUtils";
import ComputerMobileList from "components/computers/ComputerMobileList";
import ComputerTable from "components/computers/ComputerTable";
import { defaultErrorHandler } from "utils/errorHandling";
import useViewport from "hooks/useViewport";
import { QUERY_CONFIG } from "constants.js";
import { parseComputers, filterComputers } from "utils/computer";

const { Search } = Input;

const useStyles = createUseStyles({
    search: { marginBottom: 20 },
});

// Get the install date of the computer / delegation as text to display
const getInstallDate = (computer) => {
    if (!computer.hasComputer) return "Not yet";

    return getWhenAgoInstalled(computer.valid);
};

const createComputerElementList = (computers, delegations) => {
    const parsedComputers = parseComputers(computers, delegations);
    const elementsList = [];

    // Create a computer element for each computer in the provided object
    Object.keys(parsedComputers).forEach((key) => {
        const computer = parsedComputers[key];
        const computerElement = {
            ...computer,
            // Key property required for React
            key,
            osDisplay: computer.hasComputer ? computer.os.display : "None",
            installDate: getInstallDate(computer),
        };

        elementsList.push(computerElement);
    });

    return elementsList;
};

const loadComputers = async () => {
    const { data } = await api.getComputers();
    return data;
};

const loadDelegations = async () => {
    const { data } = await api.getDelegations();
    return data;
};

function ComputerList() {
    const {
        data: computers,
        error: computerError,
        status: computerStatus,
    } = useQuery("computers", loadComputers, QUERY_CONFIG);
    const {
        data: delegations,
        error: delegationError,
        status: delegationStatus,
    } = useQuery("delegations", loadDelegations, QUERY_CONFIG);

    const computerElementsList = useMemo(
        () => createComputerElementList(computers, delegations),
        [computers, delegations],
    );

    const [searchValue, setSearchValue] = useState("");
    const [filteredComputers, setFilteredComputers] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        const result = filterComputers(computerElementsList, searchValue);
        setFilteredComputers(result);
    }, [computerElementsList, searchValue]);

    if (computerStatus === "error") defaultErrorHandler(computerError);
    if (delegationStatus === "error") defaultErrorHandler(delegationError);
    const loading = computerStatus === "loading" || delegationStatus === "loading";

    const isMobile = useViewport().width < 850;

    return (
        <>
            <Search
                placeholder="Input search text"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                className={classes.search}
            />
            {isMobile ? (
                <ComputerMobileList computers={filteredComputers} loading={loading} />
            ) : (
                <ComputerTable computers={filteredComputers} loading={loading} />
            )}
        </>
    );
}

export default ComputerList;
