import { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown, Button, List } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";
import { differenceInSeconds } from "date-fns";

import ComputerCard from "components/computers/ComputerCard";

const useStyles = createUseStyles({
    button: { padding: 0 },
});

const sortComputers = (computers, sortKey) => {
    const returnComputers = computers;

    switch (sortKey) {
        case "computer-name":
            returnComputers.sort((a, b) => a.name.localeCompare(b.name));
            break;
        case "os-name":
            returnComputers.sort((a, b) => {
                if (a.os === undefined) {
                    return -1;
                }

                if (b.os === undefined) {
                    return 1;
                }

                return a.os.display.localeCompare(b.os.display);
            });
            break;
        case "install-date":
            returnComputers.sort((a, b) => {
                if (a.valid === undefined) {
                    return -1;
                }

                if (b.valid === undefined) {
                    return 1;
                }

                return differenceInSeconds(new Date(b.valid), new Date(a.valid));
            });
            break;
        case "install-status":
            returnComputers.sort((a, b) => {
                if (a.installPercentage === undefined) {
                    return -1;
                }

                if (b.installPercentage === undefined) {
                    return 1;
                }

                return a.installPercentage - b.installPercentage;
            });
            break;
        default:
            console.error("Unknown event");
    }

    return returnComputers;
};

function ComputerMobileList({ computers, loading }) {
    const [sortKey, setSortKey] = useState("install-date");
    const classes = useStyles();

    const menu = (
        <Menu onClick={({ key }) => setSortKey(key)}>
            <Menu.Item key="computer-name">Computer Name</Menu.Item>
            <Menu.Item key="install-date">Install date</Menu.Item>
            <Menu.Item key="install-status">Install status</Menu.Item>
            <Menu.Item key="os-name">Operating System</Menu.Item>
        </Menu>
    );

    const sortedComputers = sortComputers(computers, sortKey);

    return (
        <>
            <Dropdown overlay={menu}>
                <Button type="link" className={classes.button}>
                    Sort by {sortKey.replace("-", " ")} <DownOutlined />
                </Button>
            </Dropdown>

            <List
                itemLayout="vertical"
                size="large"
                dataSource={sortedComputers}
                loading={loading}
                pagination={{}}
                renderItem={(row) => <ComputerCard computer={row} />}
            />
        </>
    );
}

ComputerMobileList.propTypes = {
    computers: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default ComputerMobileList;
