import { useEffect } from "react";
import { Form, Select } from "antd";

import { defaultErrorHandler } from "utils/errorHandling";
import useFormStore from "hooks/useFormStore";
import api from "api";

const { Option } = Select;

function OperatingSystemSelector() {
    const computerName = useFormStore((state) => state.computerName);
    const operatingSystem = useFormStore((state) => state.operatingSystem);
    const operatingSystems = useFormStore((state) => state.operatingSystems);
    const setOperatingSystems = useFormStore((state) => state.setOperatingSystems);
    const setOperatingSystem = useFormStore((state) => state.setOperatingSystem);
    const setOptions = useFormStore((state) => state.setOptions);
    const getOperatingSystemOptions = useFormStore((state) => state.getOperatingSystemOptions);
    const isInvalid = useFormStore((state) => state.invalidOptions).includes("os");

    useEffect(async () => {
        try {
            const response = await api.getOperatingSystems(computerName);
            setOperatingSystems(response.data);
        } catch (error) {
            defaultErrorHandler(error);
        }
    }, []);

    // Handles action when selected operating system has changed and saves newly selected operating system into state
    // Fetches matching options from backend API for newly selected operating system and saves them to state for display
    const onOSChange = (newOperatingSystem) => {
        // Save newly selected operating system to state
        // Cleanup options from previous operating system
        setOperatingSystem(newOperatingSystem);
        setOptions([]);

        if (newOperatingSystem !== "none") {
            getOperatingSystemOptions(newOperatingSystem, computerName);
        }
    };

    const attributes = isInvalid ? { validateStatus: "error", help: "Please select an Operating System" } : {};

    return (
        <Form.Item label="Operating System" {...attributes}>
            <Select
                showSearch
                value={operatingSystem}
                onChange={onOSChange}
                placeholder="Select an Operating System"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                <Select.Option value="none">Please select</Select.Option>
                {operatingSystems.map((os) => (
                    <Option value={os.name} key={os.name}>
                        {os.display}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export default OperatingSystemSelector;
