import axios from "axios";
import AuthenticationService from "utils/authentication";
import { API_URL } from "configuration";

const anonymousCredentials = {
    username: "anonymous",
    password: "anonymous",
};

// Get all computers with detailed information for each computer
const getComputers = () =>
    axios.get(`${API_URL}/computer/?recursive=1`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get all delegations with detailed information for each delegation
const getDelegations = () =>
    axios.get(`${API_URL}/delegation/?recursive=1&by=all`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Delete computer for a given computer name
const deleteComputer = (computerName) =>
    axios.delete(`${API_URL}/computer/${encodeURIComponent(computerName)}`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Delete delegation for a given delegation name
const deleteDelegation = (delegationId) =>
    axios.delete(`${API_URL}/delegation/${encodeURIComponent(delegationId)}`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Test wether the inputed hostname for a computer is valid
const testComputerName = (computerName) =>
    axios.post(
        `${API_URL}/computer?test=1`,
        { name: computerName },
        {
            auth: AuthenticationService.getAuthentication(),
        },
    );

// Test wether the inputed template values are correct
// Will return the name of the options which are invalid
const testTemplate = (values) =>
    axios.post(`${API_URL}/template?test=1`, values, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get all available operating systems for a given computer name
const getOperatingSystems = (computerName) =>
    axios.get(`${API_URL}/os?recursive=1&computer=${encodeURIComponent(computerName)}`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get the details of a specific option for an operating system
const getOperatingSystemOption = (operatingSystem, option) =>
    axios.get(`${API_URL}/os/${encodeURIComponent(operatingSystem)}/option/${encodeURIComponent(option)}`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get all operating systems options for a given operating system
// Possibility to provide already filled in options, which might affect the output
const getOperatingSystemOptions = (operatingSystem, values) =>
    axios.post(`${API_URL}/os/${encodeURIComponent(operatingSystem)}/option/?recursive=1`, values, {
        auth: AuthenticationService.getAuthentication(),
    });

// Saves a template for a computer
const saveTemplate = (values) =>
    axios.post(`${API_URL}/template`, values, {
        auth: AuthenticationService.getAuthentication(),
    });

// Starts the computer installation of a specific template that already has to exist
const saveComputer = (values) =>
    axios.post(`${API_URL}/computer`, values, {
        auth: AuthenticationService.getAuthentication(),
    });

// Saves a delegation for an existing template / computer
const saveDelegation = (values) =>
    axios.post(`${API_URL}/delegation/?silent=0`, values, {
        auth: AuthenticationService.getAuthentication(),
    });

// Tests if a given username is valid and exists
const testUser = (username) =>
    axios.get(`${API_URL}/user/${encodeURIComponent(username)}`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get the history for a given computer name
const getComputerHistory = (computerName) =>
    axios.get(`${API_URL}/computer/${encodeURIComponent(computerName)}/?all=1&recursive=1`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get the status history for a given computer name
const getStatusHistory = (computerName) =>
    axios.get(`${API_URL}/status/${encodeURIComponent(computerName)}/?last=0`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Set a new status for a given computer name
const setComputerStatus = (computerName, values) =>
    axios.post(`${API_URL}/status/${encodeURIComponent(computerName)}`, values, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get all templates associated with a user
const getTemplates = () =>
    axios.get(`${API_URL}/template/?all=0&recursive=1`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Delete a template
const deleteTemplate = (template) =>
    axios.delete(`${API_URL}/template/${template}`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Rename a template
const renameTemplate = (template, name) => {
    const values = {
        name,
    };

    return axios.patch(`${API_URL}/template/${template}`, values, {
        auth: AuthenticationService.getAuthentication(),
    });
};

// Get template details
const getTemplate = (template) =>
    axios.get(`${API_URL}/template/${template}`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get the options for a template
const getTemplateOptions = (template) =>
    axios.get(`${API_URL}/template/${template}/option`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get user details with given credentials
const getUser = (credentials) =>
    axios.get(`${API_URL}/user`, {
        auth: credentials,
    });

// Get the IP and Hostname of the client
const getIp = () =>
    axios.get(`${API_URL}/ip`, {
        auth: AuthenticationService.getAuthentication(),
    });

// Get the API documentation
const getAPIDocumentation = (authenticated = true) =>
    axios.get(`${API_URL}/internal/api`, {
        auth: authenticated ? AuthenticationService.getAuthentication() : anonymousCredentials,
    });

const api = {
    getComputers,
    getDelegations,
    deleteComputer,
    deleteDelegation,
    testComputerName,
    testTemplate,
    getOperatingSystems,
    getOperatingSystemOption,
    getOperatingSystemOptions,
    saveTemplate,
    saveComputer,
    saveDelegation,
    testUser,
    getComputerHistory,
    getStatusHistory,
    setComputerStatus,
    getTemplates,
    deleteTemplate,
    renameTemplate,
    getTemplate,
    getTemplateOptions,
    getUser,
    getIp,
    getAPIDocumentation,
};

export default api;
