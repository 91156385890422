import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Form, Input, Button, message } from "antd";

import useFormStore from "hooks/useFormStore";
import { useDebounce } from "hooks/debounce";
import { extractMessage } from "utils/errorHandling";
import api from "api";

const useStyles = createUseStyles({
    hostname: {
        maxWidth: 700,
        padding: "24px 24px 0",
    },
});

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 },
    },
};

function ComputerNameInput() {
    const classes = useStyles();

    const computerName = useFormStore((state) => state.computerName);
    const setComputerName = useFormStore((state) => state.setComputerName);
    const validation = useFormStore((state) => state.computerNameValidation);
    const setValidation = useFormStore((state) => state.setComputerNameValidation);

    const debouncedComputerName = useDebounce(computerName, 300);

    useEffect(() => {
        if (debouncedComputerName !== "") {
            testComputerName(debouncedComputerName);
        } else {
            // Remove errors if field empty
            setValidation({});
        }
    }, [debouncedComputerName]);

    const testComputerName = async (computerName) => {
        setValidation({ validateStatus: "validating" });

        try {
            const response = await api.testComputerName(computerName);
            if (response.data) {
                setValidation({
                    validateStatus: "error",
                    help: response.data.message,
                });
                return;
            }

            setValidation({
                validateStatus: "success",
                hasFeedback: true,
                help: "This Computer exists and you can install it.",
            });
        } catch (error) {
            setValidation({
                validateStatus: "error",
                help: extractMessage(error),
            });
        }
    };

    const fetchHostname = () => {
        api.getIp().then((response) => {
            const { hostname } = response.data;
            if (hostname) {
                setComputerName(hostname);
            } else {
                message.info("Unable to determine hostname");
            }
        });
    };

    return (
        <div className={classes.hostname}>
            <Form {...formItemLayout} layout="horizontal" initialValues={{ layout: "horizontal" }}>
                <Form.Item label="Computer Name" {...validation} hasFeedback>
                    <Input value={computerName} onChange={(event) => setComputerName(event.target.value)} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button onClick={fetchHostname}>Get Hostname</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ComputerNameInput;
