import PropTypes from "prop-types";
import { Collapse } from "antd";

import JSONDisplay from "components/utils/JSONDisplay";
import Example from "components/api/ApiExample";

const { Panel } = Collapse;

function ExamplePanel(examples, method) {
    if (!Array.isArray(examples)) return null;
    if (examples.length === 0) return null;

    return (
        <Panel header="Examples" key="examples">
            {examples.map((example, index) => (
                <Example example={example} method={method} key={index} />
            ))}
        </Panel>
    );
}

function RequestPanel(request) {
    if (!request) return null;

    return (
        <Panel header="Request" key="request">
            <JSONDisplay data={request} />
        </Panel>
    );
}

function ResponsePanel(response) {
    if (!response) return null;

    return (
        <Panel header="Response" key="response">
            <JSONDisplay data={response} />
        </Panel>
    );
}

function ApiCollapse({ documentation, method }) {
    if (!documentation) return null;

    // WARNING: AntDesign Collapse doesn't work with regular components
    return (
        <Collapse>
            {ExamplePanel(documentation.examples, method)}
            {RequestPanel(documentation.request)}
            {ResponsePanel(documentation.response)}
        </Collapse>
    );
}

ApiCollapse.propTypes = {
    documentation: PropTypes.object.isRequired,
    method: PropTypes.string.isRequired,
};

export default ApiCollapse;
