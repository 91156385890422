import { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Menu, Dropdown, Button, message } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import CustomModal from "components/utils/CustomModal";
import { defaultErrorHandler } from "utils/errorHandling";
import api from "api";

function TemplateMenu({ template }) {
    const history = useHistory();
    const queryClient = useQueryClient();

    const [visible, setVisible] = useState(false);
    const [modal, setModal] = useState({
        title: "",
        text: "",
        type: "",
        onOk: () => {},
        onCancel: () => setVisible(false),
    });

    const { mutate: dispatchRename, isLoading: renameLoading } = useMutation(
        ({ id, value }) => api.renameTemplate(id, value),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("templates");
                message.success("Template renamed");
            },
            onError: defaultErrorHandler,
            onSettled: () => setVisible(false),
        },
    );

    const { mutate: dispatchReinstall, isLoading: reinstallLoading } = useMutation(
        (request) => api.saveComputer(request),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries("computers");
                message.success("Reinstall sucessfully scheduled. Please rebooot to initiate.");
                history.push(`/computers/${variables.name}`);
            },
            onError: defaultErrorHandler,
            onSettled: () => setVisible(false),
        },
    );

    const { mutate: dispatchDelete, isLoading: deleteLoading } = useMutation(({ id }) => api.deleteTemplate(id), {
        onSuccess: () => {
            queryClient.invalidateQueries("templates");
            message.success("Template deleted");
        },
        onError: defaultErrorHandler,
        onSettled: () => setVisible(false),
    });

    const loading = renameLoading || reinstallLoading || deleteLoading;

    const reinstallAction = (value) => {
        dispatchReinstall({ name: value, template: template.id });
    };

    const renameAction = async (value) => {
        if (value === "") return message.warn("Template name cannot be empty");
        dispatchRename({ id: template.id, value });
    };

    const deleteAction = async () => {
        dispatchDelete({ id: template.id });
    };

    const handleActionClick = ({ key: action }) => {
        switch (action) {
            case "template":
                history.push(`/form/template/${template.id}`);
                break;
            case "immediate-reinstall": {
                setModal({
                    ...modal,
                    title: "Enter a computer name to install with this template",
                    onOk: reinstallAction,
                    type: "validation",
                });
                setVisible(true);
                break;
            }
            case "rename": {
                setModal({
                    ...modal,
                    title: `Rename Template ${template.name}`,
                    onOk: renameAction,
                    type: "input",
                });
                setVisible(true);
                break;
            }
            case "delete": {
                setModal({
                    ...modal,
                    title: `Do you want to delete ${template.name}`,
                    onOk: deleteAction,
                    type: "confirm",
                });
                setVisible(true);
                break;
            }
            default:
                message.error("Unknown action");
        }
    };

    return (
        <>
            <Menu onClick={handleActionClick}>
                <Menu.Item key="template">Use Template</Menu.Item>
                <Menu.Item key="immediate-reinstall">Immediate Reinstall</Menu.Item>
                <Menu.Item key="rename">Rename</Menu.Item>
                <Menu.Item key="delete">Delete</Menu.Item>
            </Menu>
            <CustomModal {...modal} loading={loading} visible={visible} />
        </>
    );
}

function TemplateDropdown({ template }) {
    const menu = <TemplateMenu template={template} />;

    return (
        <Dropdown overlay={menu}>
            <Button shape="circle" icon={<MenuOutlined />} />
        </Dropdown>
    );
}

const props = {
    template: PropTypes.object.isRequired,
};

TemplateMenu.propTypes = props;
TemplateDropdown.propTypes = props;

export default TemplateDropdown;
