import { Divider } from "antd";

import FormOption from "components/form/FormOption";
import MissingOption from "components/form/MissingOption";
import useFormStore from "hooks/useFormStore";

function FormOptionList() {
    const options = useFormStore((state) => state.options);
    const missingOptions = useFormStore((state) => state.missingOptions);

    const optionElements = options.map((option) => (
        <div key={option.name}>
            <Divider />
            <FormOption option={option} />
        </div>
    ));

    const missingOptionElements = Object.keys(missingOptions).map((key) => (
        <MissingOption key={key} option={{ display: key, value: missingOptions[key] }} />
    ));

    return optionElements.concat(missingOptionElements);
}

export default FormOptionList;
