import { createUseStyles } from "react-jss";
import { Spin, Empty } from "antd";

import DocumentationText from "components/api/DocumentationText";
import ApiFunction from "components/api/ApiFunction";
import { useApiDocumentation } from "hooks/apiDocumentation";
import { defaultErrorHandler } from "utils/errorHandling";

const useStyles = createUseStyles({
    list: {
        background: "#fff",
        padding: 24,
    },
});

function ApiList() {
    const classes = useStyles();
    const { data, status, error } = useApiDocumentation();

    if (status === "error") {
        defaultErrorHandler(error);
    }

    if (data.length === 0) {
        return (
            <div className={classes.list}>
                <DocumentationText />
                <Spin spinning={status === "loading"}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Spin>
            </div>
        );
    }

    return (
        <div className={classes.list}>
            <DocumentationText />
            {data.map((docElement) => (
                <ApiFunction action={docElement} key={docElement.url + docElement.documentation.description} />
            ))}
        </div>
    );
}

export default ApiList;
