import PropTypes from "prop-types";
import OperatingSystemLogo from "components/util-components/OperatingSystemLogo";

const NamedOS = ({ os }) => (
    <>
        <OperatingSystemLogo os={os.name} />
        <span style={{ marginLeft: 10 }}>{os.display}</span>
    </>
);

NamedOS.propTypes = {
    os: PropTypes.object.isRequired,
};

export default NamedOS;
