import { useReducer, useEffect } from "react";
import api from "api";
import { extractMessage } from "utils/errorHandling";

const initialState = {
    status: null,
    message: null,
};

const computerValidationReducer = (_, action) => {
    switch (action.type) {
        case "INIT":
            return {
                status: "validating",
                message: null,
            };
        case "SUCCESS":
            return {
                status: "success",
                message: "This computer exists and you can install it",
            };
        case "ERROR":
            return {
                status: "error",
                message: action.payload,
            };
        default:
            throw new Error();
    }
};

const useComputerNameVallidation = (name) => {
    const [state, dispatch] = useReducer(computerValidationReducer, initialState);

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "INIT" });

            try {
                await api.testComputerName(name);
                dispatch({ type: "SUCCESS" });
            } catch (error) {
                const message = extractMessage(error);
                dispatch({ type: "ERROR", payload: message });
            }
        };

        if (name !== "") fetchData();
    }, [name]);

    return state;
};

export { useComputerNameVallidation };
