import PropTypes from "prop-types";
import JSONPretty from "react-json-pretty";

const theme = {
    main: "line-height:1.3;color:#000000;background:#f6f8fa;overflow:auto;",
    error: "line-height:1.3;color:#000000;background:#f6f8fa;overflow:auto;",
    key: "color:#fc5d5d;",
    string: "color:#1a9b00;",
    value: "color:#bb9b0d;",
    boolean: "color:#538fff;",
};

const JSONDisplay = ({ data }) => <JSONPretty theme={theme} mainStyle="padding:1em" data={data} />;

JSONDisplay.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default JSONDisplay;
