import { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select, Checkbox, message, Modal } from "antd";

import MultitextFormOption from "components/form/MultitextFormOption";
import useFormStore from "hooks/useFormStore";

const tailLayout = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 },
    },
};

const getOptionValue = (type, event) => {
    if (type === "bool") return event.target.checked;
    if (type === "select" || type === "multiselect") return event;
    return event.target.value;
};

function FormOption({ option }) {
    const isInvalid = useFormStore((state) => state.invalidOptions).includes(option.name);
    const finishOptionChange = useFormStore((state) => state.finishOptionChange);
    const optionValues = useFormStore((state) => state.optionValues);
    const updateOptionValue = useFormStore((state) => state.updateOptionValue);
    const validating = useFormStore((state) => state.loadingOptions).includes(option.name);

    const value = optionValues[option.name];

    useEffect(() => {
        if (option.type === "password" && value !== "") {
            Modal.warning({
                title: "Attention: Password not readable",
                content:
                    "Passwords cannot be read from database. Please re-enter it in the form or (re)install the computer with this deplate directly (without modifying it)!",
            });
        }
    }, [option, value]);

    // Gets called for an input change that should only modify the state and not do any validation
    const handleChange = (event) => {
        const value = getOptionValue(option.type, event);

        updateOptionValue(option.name, value);
    };

    // Gets called for an input that modifys and state and validates the input
    const handleFinishedChange = (event) => {
        const value = getOptionValue(option.type, event);

        finishOptionChange(option.name, value);
    };

    // Gets the error message for an option
    // Depends on wether there is an error and the type of the option
    const getAttributes = () => {
        if (validating) {
            return { validateStatus: "validating" };
        }

        if (!isInvalid) {
            return {};
        }

        const attributes = {
            validateStatus: "error",
        };

        switch (option.type) {
            case "int":
                attributes.help = (
                    <span>
                        <b>Error:</b> The number must be between <code>{option.min}</code> and <code>{option.max}</code>
                    </span>
                );
                break;
            case "password":
            case "text":
                attributes.help = (
                    <span>
                        <b>Error:</b> The value must match the following expression: <br /> <code>{option.regex}</code>
                    </span>
                );
                break;
            default:
                attributes.help = (
                    <span>
                        <b>Error:</b> This value is invalid
                    </span>
                );
        }

        return attributes;
    };

    // MARK: Render methods

    const renderBool = () => (
        <>
            <Form.Item {...getAttributes()} {...tailLayout} hasFeedback>
                <Checkbox checked={value} onChange={handleFinishedChange}>
                    {option.display}
                </Checkbox>
            </Form.Item>
            {option.description && (
                <Form.Item {...tailLayout}>
                    <p>{option.description}</p>
                </Form.Item>
            )}
        </>
    );

    const renderInt = () => (
        <>
            <Form.Item label={option.display} {...getAttributes()} hasFeedback>
                <Input value={value} onChange={handleChange} onBlur={handleFinishedChange} />
            </Form.Item>
            {option.description && (
                <Form.Item {...tailLayout}>
                    <p>{option.description}</p>
                </Form.Item>
            )}
        </>
    );

    const renderMultiselect = () => (
        <>
            <Form.Item label={option.display} {...getAttributes()} hasFeedback>
                <Select
                    mode="multiple"
                    placeholder="Please select"
                    style={{ width: "100%" }}
                    value={value}
                    onChange={handleFinishedChange}
                >
                    {option.values.map((element) => (
                        <Select.Option value={element.name} key={element.name}>
                            {element.display}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {option.description && (
                <Form.Item {...tailLayout}>
                    <p>{option.description}</p>
                </Form.Item>
            )}
        </>
    );

    const renderPassword = () => (
        <>
            <Form.Item label={option.display} {...getAttributes()} hasFeedback>
                <Input.Password value={value} onChange={handleChange} onBlur={handleFinishedChange} />
            </Form.Item>
            {option.description && (
                <Form.Item {...tailLayout}>
                    <p>{option.description}</p>
                </Form.Item>
            )}
        </>
    );

    const renderSelect = () => (
        <>
            <Form.Item label={option.display} {...getAttributes()}>
                <Select
                    value={value}
                    onChange={handleFinishedChange}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, filterOption) =>
                        filterOption.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {option.values.map((element) => (
                        <Select.Option value={element.name} key={element.name}>
                            {element.display}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {option.description && (
                <Form.Item {...tailLayout}>
                    <p>{option.description}</p>
                </Form.Item>
            )}
        </>
    );

    const renderText = () => (
        <>
            <Form.Item label={option.display} {...getAttributes()} hasFeedback>
                <Input value={value} onChange={handleChange} onBlur={handleFinishedChange} />
            </Form.Item>
            {option.description && (
                <Form.Item {...tailLayout}>
                    <p>{option.description}</p>
                </Form.Item>
            )}
        </>
    );

    switch (option.type) {
        case "bool":
            return renderBool();
        case "int":
            return renderInt();
        case "multiselect":
            return renderMultiselect();
        case "password":
            return renderPassword();
        case "select":
            return renderSelect();
        case "text":
            return renderText();
        case "multitext":
            return <MultitextFormOption option={option} />;
        default:
            message.error("Option type could not be found");
            return <p>{option.name} cannot be displayed</p>;
    }
}

FormOption.propTypes = {
    option: PropTypes.object.isRequired,
};

export default FormOption;
